/**
 * IE specific media query
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /**
     * Disable buggy 3rd party authentication
     */
    .social-media-title,
    .google-login,
    .facebook-login {
        display: none !important;
    }
}

.comment-document-title {
    height: 15px;
    display: inline;
    color: #8c8c8c;
    transition: all 0.2s;
    text-decoration: underline;
}

@media screen and (max-width: 1199px) {
    .update-button-col {
        display: flex;
        justify-content: flex-end;
    }
}

.remove-focus-from-button:focus {
    color: rgba(0, 0, 0, 0.65);
    border-color: #d9d9d9;
}

.comment-document-title:hover {
    color: #262626;
}

/**
 * Tab content center
 */
.ant-tabs-nav-scroll {
    text-align: center;
}

.containerTabs .ant-tabs-nav-scroll {
    text-align: left;
}

/**
 * Tree view break words at line end
 */
.ant-tree li {
    white-space: normal !important;
}

.ant-tree li .ant-tree-node-content-wrapper {
    display: inline !important;
}

/**
 * PDF preview
 */
.pdf-doc-container {
    overflow: hidden;
    padding-bottom: 16px;
    background-color: #fff;
}

.ant-card-bordered {
    border: none !important;
}

.myCard {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: #FFCB00 !important;
}

.cardTitle {
    table-layout: fixed;
    display: table;
}

.pdf-doc-page {
    margin: 0 auto;
    border-radius: 4px;
    left: +7px;
    position: relative;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.09) 0 2px 8px 1px;
}

.pdf-doc-page-wrapper {
}

.scroll-container {
    box-shadow: rgba(0, 0, 0, 0.09) 0 2px 8px 1px;
    border: 1px solid rgba(0, 0, 0, 0.09);
}

.sign {
    position: absolute;
    top: -31px;
    right: -26px;
}

/**
 * Resize pdf preview for mobile
 */
@media only screen and (max-width: 641px) {
    .pdf-doc-page {
        width: 240px !important;
        height: auto !important;
    }
}

/**
 * Tab card type to look nice
 */
.ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;
}

.ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
}

.card-container {
    background: #fff;
    padding: 16px;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border: none;
}

/**
 * Container list margin usless
 */
.ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0 !important;
}

/**
 * Avatar img size default 32px only
 */
.ant-comment-avatar img {
    width: auto !important;
    height: auto !important;
    border-radius: 0%;
}

.login-links-right {
    text-align: right;
}

/**
 * Fix login screen bottom links to align correctly
 */
@media only screen and (max-width: 767px) {
    .login-links-right {
        text-align: left !important;
    }
}

/**
 * Fix antd RangePicker separator position
 */
.ant-calendar-range-picker-separator {
    vertical-align: unset !important;
}

/**
 * Fix antd type="link" buttons hover color
 */
.ant-btn-link:hover,
.ant-btn-link:focus {
    color: #fc9c35 !important;
}

.ant-btn {
    white-space: normal !important;
}

/**
 * Hack mobile view little arrow on the right to align nicely.
 */
left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
    padding: 0 30px 0 16px;
}

/*
* To set the active container
*/
.table-select {
    background-color: #fcfcfc;
    color: #ed7821;
}

/*
* To set the unseen container
*/
.table-unseen-row {
    background-color: #f4f5f0;
    font-weight: bold;
}

/*
* To disable table row
*/
.disabled-row {
    background-color: #dcdcdc;
    pointer-events: none;
}

/**
 * Cégképviseleti igazolás dropdown elválasztó színe
 */
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #FFCB00 !important;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #FFCB00 !important;
}

/**
 * RESIZE
 */
.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position: absolute;
    width: 30px;
    height: 30px;
}

.react-resizable-handle-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize;
}

/**
* sore-link button position on mobile
*/
@media screen and (max-width: 400px) {
    #storeLink {
        flex-wrap: wrap;
    }
}

/**
* FONT
*/
@font-face {
    font-family: CustomFont;
    src: url('../Verdana.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.ant-col-sm-offset-4 {
    margin-left: 0;
}

p.info {
    text-align: justify;
}

ul.info {
    line-break: normal;
}

li.info {
    line-break: normal;
}

li.first-child {
    margin-top: 1px;
}

.ant-popover-title {
    font-weight: bold;
    font-size: medium;
}

img.info {
    width: 430px;
    height: 86px;
}

.resize-corner {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-bottom: 4px solid rgb(250, 219, 20);
    border-right: 4px solid rgb(250, 219, 20);
}

.signature-profile-blank-page {
    padding: 60px 40px 40px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    margin: 0 auto;
}

.userInfo {
    width: 100%;
}
.userInfo td {
    padding: 1rem;
}

.userInfo td {
    border: 1px solid black;
}

.fc {
    width: 30%;
    background-color: rgba(61, 61, 61, 0.36);
}

/* Unnecessary padding in comments */
.ant-comment-avatar, .ant-comment-inner {
    margin: 0px !important;
    padding: 0px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
}

.active.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    padding-bottom: 10px;
    margin: 0;
    background: #fff;
}

.active.ant-collapse > .ant-collapse-item > .ant-collapse-content {
    padding: 0;
    padding-bottom: 10px;
    margin: 0;
    background: #fff;
}

/* Weird padding in modal footers */
.ant-modal-footer {
    padding: 10px !important;
}

/* Too much padding on tabs - padding of parent components is always enough  */
.ant-tabs-tab {
    padding-bottom: 8px !important;
    padding-top: 0px !important;
}

/* It is hard to read the placeholder text in empty tables */
.ant-table-placeholder {
    color: rgba(0, 0, 0, 0.65) !important;
}

.ant-input-group-addon{
    padding: 0px !important;
}

.ant-input-number-handler-wrap {
    visibility: hidden;
    width:0px !important;
}
